import React, {useState} from 'react'
import { useParams } from 'react-router-dom';

import { Layout, Button, Alert, Form, Input, Typography } from 'antd';

const { Text, Title } = Typography;

export default function PasswordReset({ isDarkMode }) {

    const [complete, setComplete] = useState(false)
    const [error, setError] = useState();
    const { token } = useParams();

    const resetPass = (formComplete) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: token, password: formComplete.password, confirmPassword: formComplete.password2 })
        };
        fetch('/api/resetpassword', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    setError(null);
                    setComplete(true);
                } else {
                    setError(data.error);
                }
            });
    }

    let siderLogo = isDarkMode ? "images/workboxLogoFullWhite-sm.png" : "images/workboxLogoFull-sm.png"

    if (complete) {
        return (
            <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            <Form
                onFinish={resetPass}
                autoComplete="off"
                style={{
                    maxWidth: 600,
                    width: '100%'
                }}
                layout="vertical"
                >
                <Form.Item wrapperCol={{ span: 24 }}>
                    <img style={{width: '140px', padding: '10px'}} src={siderLogo} alt='' />
                    <Title level={3}>Reset your password</Title>
                    <Text>Please enter your new password below.</Text>
                    <Alert style={{marginTop: 15}} message="Password Reset Sucessfully" type="success" />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                    {
                        required: true,
                    },
                    { min: 6, message: 'Password must be minimum 6 characters.' },
                    ]}
                >
                    <Input.Password disabled />
                </Form.Item>

                {/* Field */}
                <Form.Item
                    label="Confirm Password"
                    name="password2"
                    dependencies={['password']}
                    rules={[
                    {
                        required: true,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password disabled />
                </Form.Item>
                <Form.Item style={{textAlign: "center"}}>
                    <Button type="primary" disabled htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            </Layout>
        )

    } else {
        return (
            <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
            <Form
                onFinish={resetPass}
                autoComplete="off"
                style={{
                    maxWidth: 600,
                    width: '100%'
                }}
                layout="vertical"
                >
                <Form.Item wrapperCol={{ span: 24 }}>
                    <img style={{width: '140px', padding: '10px'}} src={siderLogo} alt='' />
                    <Title level={3}>Reset your password</Title>
                    <Text>Please enter your new password below.</Text>
                    {error && <Alert style={{marginTop: 15}} message={error} type="error" />}
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                    {
                        required: true,
                    },
                    { min: 6, message: 'Password must be minimum 6 characters.' },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                {/* Field */}
                <Form.Item
                    label="Confirm Password"
                    name="password2"
                    dependencies={['password']}
                    rules={[
                    {
                        required: true,
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            </Layout>
        )
    }
}
