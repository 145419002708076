import React from 'react'
import { Button, Result } from 'antd';

export default function Expired() {
  return (
    <Result
    status="warning"
    title="This link has expired. Please request a new one!"
  />
  )
}
