import React from 'react'
import { Button, Result, Layout } from 'antd';

export default function NotFound() {
  return (
    <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center'}}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={() => {window.location = "https://workbox.live"}} type="primary">Back Home</Button>}
      />
    </Layout>
  )
}
