import React, { useState, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, theme, App as AntdApp } from "antd";

import 'antd/dist/reset.css'
import './index.css';


const customTokenlight = {
    // colorBgBase: '#EEE',
    // controlItemBgActive: '#cae1f3'
};

const customTokendark = {
    colorBgBase: '#161616',
    // colorPrimaryBg: 'rgba(255, 255, 255, 0.25)',
    colorBgElevated: '#262626'
};

const stylesheets = {
    light: "antd.light.css",
    dark: "antd.dark.css"
}
  
const createStylesheetLink = () => {
    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.id = "antd-stylesheet"
    document.head.appendChild(link)
    return link
}

const getStylesheetLink = () =>
  document.head.querySelector("#antd-stylesheet") || createStylesheetLink()

const getTheme = () =>
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light"



function Root() {
    const { defaultAlgorithm, darkAlgorithm } = theme;

    const [isDarkMode, setIsDarkMode] = useState(getTheme() === "dark" ? true : false);

    const setTheme = () => {
        let newTheme = !isDarkMode? "dark" : "light"

        localStorage.setItem("theme", newTheme)
        getStylesheetLink().href = stylesheets[newTheme]
        setIsDarkMode((previousValue) => !previousValue);
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                token: isDarkMode ? customTokendark : customTokenlight
            }
        }>
            {/* <StrictMode> */}
                <AntdApp>
                    <BrowserRouter>
                        <App isDarkMode={isDarkMode} />
                    </BrowserRouter>
                </AntdApp>
            {/* </StrictMode> */}
        </ConfigProvider>
    );
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( <Root /> );