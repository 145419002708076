import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Space, Alert } from 'antd';

import "./links.css";
import { useParams } from 'react-router-dom';


export default function Login({ getLogin, isDarkMode }) {
    const inputEl = useRef(null);

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const params = useParams();
    
    const onFinish = (values) => {
        console.log(values);
        setLoading(true);
        getLogin(values, (error) => {
            setError(error)
            setLoading(false);
        });
    };

    useEffect(() => {
        inputEl.current.focus();
        inputEl.current.select();
    }, []);

    console.log(isDarkMode);

    let siderLogo = isDarkMode ? "images/workboxLogoFullWhite-sm.png" : "images/workboxLogoFull-sm.png"

    return (
        <Layout style={{minHeight: '100vh'}}>
            <div className="login-container">
                <Form
                    name="basic"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    style={{ width: 400 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <img style={{width: '140px', padding: '10px'}} src={siderLogo} alt='' />
                        <h4>This link is password protected.</h4>
                        <h5>Please enter your password below.</h5>
                        {error && <Alert message={error} type="error" />}
                    </Form.Item>

                    <Form.Item
                        style={{ marginLeft: -111 }}
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                    <Input.Password ref={inputEl} />
                    </Form.Item>


                    <Form.Item wrapperCol={{ span: 24 }} style={{paddingTop: 10}}>
                        <Space>
                            <Button disabled={loading} loading={loading} type="primary" htmlType="submit">Login</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    )
}