import React, { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd';
import './links.css';

export default function Open({ url }) {

    const [loading, setLoading] = useState(true)
    const iframeRef = useRef()

    let iframeURL = url.replace("dl=0", "raw=1");

    const handleIfrmeLoad = () => {
        setLoading(false);
    }

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return undefined;
    
        iframe.addEventListener("load", handleIfrmeLoad);
    
        return () => {
          iframe.removeEventListener("load", handleIfrmeLoad);
        };
    });

    return (
        <div className='openContainer'>
            <Spin tip="Loading" spinning={loading} fullscreen size="large" />
            <iframe ref={iframeRef} className='openIframe' src={iframeURL} frameborder="0"></iframe>
        </div>
    )
}
