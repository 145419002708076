import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';
import Login from './Login';
import Expired from './Expired';
import Open from './Open';
import NotFound from '../NotFound';


export default function Links({ isDarkMode }) {
    const [retResp, setRetResp] = useState("spin");
    const [link, setLink] = useState()

    const { id } = useParams();
    

    useEffect(() => {
        fetch(`/api/link/${id}`)
            .then((response) => response.json())
            .then(response => {
                if (response.ok) {
                    setLink(response);
                } else {
                    setRetResp(null);
                }
                console.log(response)
            })
            .catch(error => console.error(error))
    }, []);

    const getLogin = (formSubmit, callback) => {
        if (formSubmit.password === link.link.permissions.password) {
            let newLink = {...link, passTrue: true}
            setLink(newLink);
            setRetResp("spin");
        } else {
            callback("Password Incorrect")
        }
    }

    useEffect(() => {
        if (!link) return;

        if (link.link.permissions) {
            if (Date.now() >= link.link.permissions.expires) {
                setRetResp("expired")
                return
            }
            if (link.link.permissions.password && !link.passTrue) {
                setRetResp("login")
            } else {
                if (link.link.method === "redirect") window.location = link.url;    
                if (link.link.method === "open") setRetResp("open");
                
            }
        }
    }, [link])


    if (retResp === "spin") return (<Spin tip="Loading" spinning={true} fullscreen size="large" />);

    if (retResp === "login") return (<Login getLogin={getLogin} isDarkMode={isDarkMode} />);

    if (retResp === "expired") return (<Expired />);

    if (retResp === "open") return (<Open url={link.url} />);

    return (<NotFound />)
}
