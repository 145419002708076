import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Result, Layout } from 'antd';

export default function EmailThankYou() {

    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const success = searchParams.get('success');    

    if (success) {
        return (
            <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center'}}>
                <Result
                    status="success"
                    title="Successfully Confirmed Your Email"
                    subTitle="Please now return to the app and login as normal."
                />
            </Layout>
        )
    }

    return (
        <Layout style={{minHeight: '100vh', display: 'flex', justifyContent: 'center'}}>
            <Result
                status="error"
                title="Confirming Email Failed!"
                subTitle={error}
            />
        </Layout>
    )
}