import React from 'react';
import { Route, Routes } from "react-router-dom";
import Login from './Login';
import NotFound from './NotFound';
import Links from './links/Links';
import EmailThankYou from './EmailThankYou';
import PasswordReset from './PasswordReset';

export default function app({ isDarkMode }) {

    const subdomain = window.location.hostname.split('.')[0];

    console.log(subdomain);

    if (["link", "l"].includes(subdomain)) {
        //LINK
        return (
            <Routes>
                <Route index element={<NotFound />} />
                <Route path=":id" element={<Links isDarkMode={isDarkMode}/>} />
            </Routes>
        )
    }

    if (["db"].includes(subdomain)) {
        //DB
        return (
            <Routes>
                <Route index element={<Login isDarkMode={isDarkMode}/>} />
                <Route path="/emailThankYou" element={<EmailThankYou isDarkMode={isDarkMode}/>} />
                <Route path="/password-reset/:token" element={<PasswordReset isDarkMode={isDarkMode}/>} />
            </Routes>
        )

    }


    return (
        <Routes>
            <Route exact path="/" element={<Login isDarkMode={isDarkMode}/>} />

            <Route path="/links">
                <Route index element={<NotFound />} />
                <Route path=":id" element={<Links isDarkMode={isDarkMode}/>} />
            </Route>

        </Routes>
    )
}