import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Space, Alert } from 'antd';

import "./app.css";
import { useParams } from 'react-router-dom';


export default function App({ isDarkMode }) {
    const inputEl = useRef(null);

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const params = useParams();
    
    console.log(params);

    console.log(window.location.hostname)
    console.log(window.location.hostname.split('.')[0])

    const onFinish = (values) => {
        console.log(values);
        setLoading(true);
    };

    useEffect(() => {
        inputEl.current.focus();
        inputEl.current.select();
    }, []);


    function getUsers() {
        fetch(`/api/users`)
        .then((response) => response.json())
        .then(users => console.log(users));
    }


    let siderLogo = isDarkMode ? "images/workboxLogoFullWhite-sm.png" : "images/workboxLogoFull-sm.png"

    return (
        <Layout style={{minHeight: '100vh'}}>
            <div className='grab-bar'></div>
            <div className="login-container">
                <Form
                    name="basic"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    style={{ width: 400 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <img style={{width: '140px', padding: '10px'}} src={siderLogo} alt='' />
                        {error && <Alert message={error} type="error" />}
                    </Form.Item>

                    <Form.Item
                        style={{ marginLeft: -111 }}
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email address!' }]}
                    >
                    <Input ref={inputEl} />
                    </Form.Item>

                    <Form.Item
                        style={{ marginLeft: -111 }}
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                    <Input.Password />
                    </Form.Item>


                    <Form.Item wrapperCol={{ span: 24 }} style={{paddingTop: 10}}>
                        <Space>
                            <Button disabled={loading} loading={loading} type="primary" htmlType="submit">Login</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Layout>
    )
}